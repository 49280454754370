import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import { forEach } from 'lodash'
import ReactPlayer from 'react-player'

import { Layout, Block } from '../components'

import { parseACF } from '../utils';
import { container, padding, bgImage, heading, wrapper, maxWidth } from '../styles/global'
import { lightGrey } from '../styles/colors';
import { media, useBreakpoint } from '../styles/utils';
import { useHover } from 'react-use';

const Services = (props) => {
    let data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const isPhone = useBreakpoint('phone');

    if (props.previewData) {
        data = JSON.parse(props.previewData.acf_json) 
    }

    console.log(data)

    const renderIntroduction = () => {
        if (!data.display_introduction) return;

        return (
            <>
                {data.introduction_heading && (
                    <Block
                        layout={'heading'}
                        content={data.introduction_heading}
                    />
                )}

                {data.introduction_text && (
                    <Block
                        layout={'text'}
                        content={data.introduction_text}
                    />
                )}
            </>
        )
    }

    const renderVideo = () => {
        const videoData = {
            video: data.video,
            use_caption: data.video_use_caption,
            caption: data.video_caption
        }

        return (
            <Block
                layout={'video'}
                {...videoData}
            />
        )
    }
    

    const renderSlides = (slides) => {
		return slides.map((item, i) => {
			if (!item.image) return;
			
			return (
				<Slide
                    key={i}
                    landscape={item.image.height < item.image.width}
				>
					<BGImage 
						className={'slider-image'}
                        image={item.image.sizes.medium2} 
					/>

                    {item.use_caption && (
                        <Caption
                            dangerouslySetInnerHTML={{__html: item.caption}}
                        />
                    )}
				</Slide>
			)
		})
	}
    
    const renderSlider = () => {
        if (!data.display_slider) return;

        return (
            <Block
                layout={'image_slider'}
                slides={data.image_slider}
                renderSlides={renderSlides}
                sliderStyles={sliderStyles}
                sliderParams={{
                    slidesPerView: 'auto',
                    spaceBetween: isPhone ? 25 : 30,
                    freeMode: true,
                    loop: true,
                }}
            />
        )
    }

    const renderHoverVideo = (item) => {
        if (!item.use_hover_video || !item.hover_video) return;
        
        const element = (hovered) => (
            <VideoWrapper
                animate={hovered ? 'visible' : 'hidden'}
                {...animatedVideoHover}
            >
                <Video
                    url={item.hover_video}
                    volume={1}
                    muted={true}
                    playing={hovered}
                    loop={true}
                />
            </VideoWrapper>
        )   
        
        const [hoverable, hovered] = useHover(element);
        return hoverable
    }

    const renderServices = () => {
        const items = data.service_list.map((item, i) => {
            return (
                <Service 
                    key={i}
                >
                    <Link
                        to={`/services/${item.slug}`}l
                    >
                        <Image
                            key={item.image.sizes.medium2}
                            src={item.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage 
                                        image={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 

                        {renderHoverVideo(item)}
                    </Link>


                    <Link
                        to={`/services/${item.slug}`}l
                    >
                        <Heading>
                            {item.title}
                        </Heading>
                    </Link>
                   
                    <Description
                        dangerouslySetInnerHTML={{__html: item.description}}
                    />
                </Service>
            )
        })

        return (
            <ServicesSection>
                <Heading>
                    {data.service_list_title}
                </Heading>
                <Grid>
                    {items}
                </Grid>
            </ServicesSection>
        )
    }
    
	return (
		<Layout
            meta={data && data.seo}
            callToAction={true}
        >
			<Wrapper>
                
                <Container>
                    {renderIntroduction()}
                    {/* {renderVideo()}  */}
                </Container>
                
                <Container>
                    {renderServices()}
                </Container>

                {renderSlider()}
            </Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
`

// Slide

const sliderStyles = css`
    height: auto;
    margin-top: 150px;
    padding-left: calc(((100vw - ${maxWidth}px) + 84px) / 2);
    cursor: grab;

    ${media.phone`
        margin-top: 32px;
        padding-left: 22px;
    `}
`

const Caption = styled.div``

const Slide = styled.div`  
    width: 440px !important;

	${BGImage} {
        height: 666px;
	}

    ${Caption} {
        margin-top: 30px;

        &, p {
            font-size: 18px;
            color: ${lightGrey};
        }
    }

    ${media.phone`
        width: 257px !important;

        ${BGImage} {
            height: 357px;
        }

        ${Caption} {
            margin-top: 20px;

            &, p {
                font-size: 14px;
            }
        }
    `}

    ${props => {
        if (props.landscape) return css`
            width: 920px !important;

            ${media.phone`
                width: 540px !important;
            `}
        `
    }}
`

// Services (Grid)

const ServicesSection = styled.div`
    margin-top: 128px;

    > ${Heading} {
        ${heading}
    }

    ${media.phone`
        margin-top: 40px;
        margin-bottom: 75px;

        > ${Heading} {
            max-width: 200px;
        }
    `}
`

// Hover Video

const animatedVideoHover = {
	variants: {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.4,
            }
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.65,
            }
        },
    }
}


const VideoWrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0%;
`

const Video = styled(ReactPlayer)`
	width: 100% !important;
    height: 100% !important;

    video { 
        object-fit: cover;
    }
`

// Service

const Service = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 50%;
    flex-basis: calc(50% - 20px);
    margin-bottom: 75px;
    position: relative;

    ${BGImage},
    ${VideoWrapper} {
        height: 458px;
        width: 100%;
    }

    ${Heading} {
        margin-top: 27px;
        font-size: 24px;
        color: ${lightGrey}
    }

    ${Description} {
        margin-top: 25px;

        &, p {
            font-size: 18px;
            color: ${lightGrey}
        }
    }

    ${media.phone`
        margin-bottom: 45px;
        
        ${BGImage},
        ${VideoWrapper} {
            height: 232px;         
        }
    `}
`

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 68px;

    ${media.phone`
        flex-direction: column;
        margin-top: 99px;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "services" } }) {
            nodes {
                acf_json
            }
        }
    }
`


export default Services

